import React from 'react';
import * as yup from 'yup';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import { useMutation } from 'react-query';
import { Redirect } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm, FieldError } from 'react-hook-form';

import gate from 'gate';

import { ForgetPasswordRequest } from 'types/api/auth';
import { Button, Input } from 'components';
import { selectUser } from 'store/selectors/user';
import { useTranslation } from 'react-i18next';

const ForgetPassword: React.FC = () => {
  const { t } = useTranslation();

  const schema = yup.object().shape({
    email: yup
      .string()
      .email(t('invalid email'))
      .required(t('field is required')),
  });

  const { loggedIn } = useSelector(selectUser);
  const { control, errors, handleSubmit } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      email: '',
    },
  });
  const { isLoading, mutate: forgetPassword } = useMutation(
    async (data: ForgetPasswordRequest) => {
      try {
        const res = await gate.forgetPassword(data);
        toast.success((res as any).message);
      } catch ({ data }) {
        toast.error(data.message);
      }
    },
  );
  const onSubmit = async (data: ForgetPasswordRequest) => {
    forgetPassword(data);
  };

  if (loggedIn) {
    return <Redirect to="/" />;
  }

  return (
    <div className=" w-full min-h-screen bg-blue-350 flex justify-center items-center">
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="lg:w-1/3 w-full shadow-small rounded-lg bg-white p-3"
      >
        <div className="w-full p-3 text-center">
          {t('title of forget password')}
        </div>
        <Controller
          as={Input}
          className="w-full p-3"
          name="email"
          type="email"
          error={((errors.email as unknown) as FieldError)?.message}
          labelClassName="pb-2"
          inputClassName="rounded-lg p-2 border-gray-300 border shadow w-full"
          title={t('Email')}
          control={control}
        />

        <div className="w-full justify-center  flex items-center pt-2 mb-3">
          <Button
            type="submit"
            className="bg-blue-600 text-sm font-semibold px-5 text-white"
            title={t('Reset')}
            isLoading={isLoading}
          />
        </div>
      </form>
    </div>
  );
};

export default ForgetPassword;
