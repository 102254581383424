import * as yup from 'yup';
import { useTranslation } from 'react-i18next';
import React, { useEffect, useLayoutEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, FieldError, useForm } from 'react-hook-form';

import { Button, Card, Input, LoadingPage } from 'components';

import DatePicker, { DayValue } from 'react-modern-calendar-datepicker';
import { RequestRegisterUser } from 'types/api/user';
import gate from 'gate';
import { toast } from 'react-toastify';

const UserForm: React.FC = () => {
  const router = useHistory();
  const cache = useQueryClient();
  const { id, action } = useParams<any>();
  const { data, isLoading: updateUserLoading } = useQuery(
    'user/' + id,
    async () => gate.user(id),
    {
      enabled: action !== 'register',
    },
  );

  console.log('data:', data);
  const { isLoading, mutate: _onSubmit } = useMutation(
    async (data: RequestRegisterUser) => {
      try {
        if (action === 'register') {
          await gate.registerUser(data);
          toast.success(t('created successfully'));
        } else {
          await gate.updateUser(data, id);
          toast.success(t('updated successfully'));
          cache.invalidateQueries('user/' + id);
        }
        router.goBack();
      } catch ({ data }) {
        toast.error(data.detail.message);
        (data.detail.response.message as string[]).forEach((msg) =>
          toast.error(msg),
        );
      }
    },
    {
      onSuccess: () => cache.invalidateQueries('users'),
    },
  );

  const [selectedDay, setSelectedDay] = useState<DayValue>();
  const { t } = useTranslation();
  const schema = yup.object().shape({
    email: yup
      .string()
      .email(t('invalid email'))
      .required(t('field is required')),
    firstName: yup.string().required(t('field is required')),
    lastName: yup.string().required(t('field is required')),
    password: yup.string().required(t('field is required')),
    startTime: yup.string().required(t('field is required')),
  });

  const defaultValues = {
    firstName: '',
    password: '',
    email: '',
    lastName: '',
    startTime: '',
  };
  const { control, setValue, handleSubmit, errors } = useForm({
    defaultValues,
    resolver: yupResolver(schema),
    mode: 'onBlur',
  });

  useEffect(() => {
    if (data) {
      console.log('data:', data);
      setValue('firstName', (data as RequestRegisterUser)?.firstName);
      setValue('lastName', (data as RequestRegisterUser).lastName);
      setValue('email', (data as RequestRegisterUser)?.email);
      setValue('startTime', (data as any)?.startTime?.substr(0, 10));
    }
  }, [data, updateUserLoading]);

  const onSubmit = (data: RequestRegisterUser) => {
    _onSubmit({
      ...data,
      startTime: new Date(
        `${selectedDay?.year}/${selectedDay?.month}/${selectedDay?.day}`,
      ),
    });
  };
  if (updateUserLoading) {
    return <LoadingPage />;
  }

  return (
    <div className="flex w-full  flex-col   px-2">
      <Card className="bg-white w-full">
        <div className="flex flex-col w-full justify-center">
          <form
            className="p-4 grid md:grid-cols-2 lg:grid-cols-3"
            onSubmit={handleSubmit(onSubmit)}
          >
            <Controller
              as={Input}
              className="m-2"
              control={control}
              name="firstName"
              inputClassName="p-2 rounded-md"
              title={t('First Name')}
              error={((errors.firstName as unknown) as FieldError)?.message}
            />
            <Controller
              as={Input}
              className="m-2"
              control={control}
              name="lastName"
              inputClassName="p-2 rounded-md"
              title={t('Last Name')}
              error={((errors.lastName as unknown) as FieldError)?.message}
            />
            <Controller
              as={Input}
              className="m-2"
              control={control}
              name="email"
              inputClassName="p-2 rounded-md"
              title={t('Email')}
              type="email"
              error={((errors.email as unknown) as FieldError)?.message}
            />
            <Controller
              as={Input}
              className="m-2"
              control={control}
              name="password"
              type="password"
              inputClassName="p-2 rounded-md"
              title={t('Password')}
              error={((errors.password as unknown) as FieldError)?.message}
            />

            <div className="m-2">
              <label className={`block pb-1 text-left font-bold text-black `}>
                {t('Start Time')}
              </label>

              <Controller
                name="startTime"
                control={control}
                render={({ onChange }: any) => (
                  <DatePicker
                    wrapperClassName="w-full"
                    value={selectedDay}
                    onChange={(e) => {
                      setSelectedDay(e);
                      onChange(e);
                    }}
                    colorPrimary="orange"
                    renderInput={({ ref }: any) => (
                      <input
                        readOnly
                        className={`w-full border-gray-300 p-2 rounded-md   text-black ${
                          errors.startTime && 'focus:border-red-600'
                        }  border`}
                        ref={ref}
                        value={
                          selectedDay
                            ? `${selectedDay.year}/${selectedDay.month}/${selectedDay.day}`
                            : ''
                        }
                      />
                    )}
                  />
                )}
              />

              <p className="text-xs h-4 p-2 w-full text-center  text-red-600">
                {((errors.startTime as unknown) as FieldError)?.message &&
                  ((errors.startTime as unknown) as FieldError)?.message}
              </p>
            </div>
            <div className="w-full pt-2 pl-4 md:col-span-2 lg:col-span-3 flex justify-end items-center">
              <Button
                isLoading={isLoading}
                type="submit"
                className="bg-blue-600 text-sm px-3 font-semibold text-white"
              >
                {t('Save')}
              </Button>
            </div>
          </form>
        </div>
      </Card>
    </div>
  );
};

export default UserForm;
