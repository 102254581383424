import React, { DetailedHTMLProps } from 'react';

export interface TextAreaProps
  extends DetailedHTMLProps<
    React.TextareaHTMLAttributes<HTMLTextAreaElement>,
    HTMLTextAreaElement
  > {
  name: string;
  error?: string;
  title?: string;
  labelClassName?: string;
  className?: string;
  textAreaClassName?: string;
  textAreaErrorClassName?: string;
  ErrorMessageClassName?: string;
}

const TextArea: React.FC<TextAreaProps> = ({
  error,
  labelClassName,
  textAreaClassName,
  textAreaErrorClassName,
  className,
  name,
  title,
  ...rest
}) => {
  return (
    <div className={className}>
      <label
        className={`block pb-1 text-left font-bold text-black ${labelClassName}`}
        htmlFor={name}
      >
        {title}
      </label>
      <textarea
        {...rest}
        className={`w-full border-gray-300  text-black ${
          error && `${textAreaErrorClassName ?? 'focus:border-red-600'}`
        } border ${textAreaClassName} `}
      />

      <p
        className={` ${
          textAreaErrorClassName ??
          'text-xs h-4 p-2 w-full text-center  text-red-600'
        }`}
      >
        {error && error}
      </p>
    </div>
  );
};

export default TextArea;
