import Spinner from 'components/Spinner';
import React, {
  ButtonHTMLAttributes,
  DetailedHTMLProps,
  ReactNode,
} from 'react';
export interface ButtonProps
  extends DetailedHTMLProps<
    ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  > {
  isLoading?: boolean;
  title?: string;
  className?: string;
  spinnerColor?: string;
  children?: ReactNode;
}

const Button: React.FC<ButtonProps> = ({
  isLoading,
  className,
  title,
  spinnerColor = 'white',
  children,
  ...other
}) => {
  return (
    <button disabled={isLoading} className={`btn  ${className}`} {...other}>
      {isLoading ? (
        <Spinner color={spinnerColor} className="w-4 h-5" />
      ) : children ? (
        children
      ) : (
        title
      )}
    </button>
  );
};

export default Button;
