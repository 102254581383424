import React, { FC, useEffect, useRef, useState } from 'react';

import {
  CompanyForm,
  DepartemtForm,
  RegisterQuestionsForm,
  UserForm,
} from 'components';

import { RequestRegisterCompany } from 'types/api/company';

type showFormTypes = {
  showUserForm: boolean;
  showDepartmentForm: boolean;
  showRegisterQuestionForm: boolean;
};
const RegisterCompany: FC = () => {
  // const { data: def } = useQuery(
  //   'defaultQuestion',
  //   async () => await gate.defaultRegisterQuestion('dutch'),
  // );
  // console.log('data:', def);
  const div = useRef<any>();
  const [data, setdata] = useState<RequestRegisterCompany>();
  const [
    { showRegisterQuestionForm, showDepartmentForm, showUserForm },
    setShowForm,
  ] = useState<showFormTypes>({
    showDepartmentForm: false,
    showRegisterQuestionForm: false,
    showUserForm: false,
  });

  const scrollToElement = () => {
    const elmnt = div.current;
    elmnt.scrollIntoView(true);
  };
  useEffect(() => {
    scrollToElement();
  }, [data, showUserForm, showDepartmentForm, showRegisterQuestionForm]);

  return (
    <div className="w-full">
      <div className="my-10">
        <div className="w-full flex justify-center items-center">
          <div className="p-3 bg-blue-600 -mt-4 text-white font-bold rounded ">
            Company
          </div>
        </div>
        <CompanyForm
          setData={(data: RequestRegisterCompany) => {
            setdata(data);
            setShowForm((prev: showFormTypes) => {
              return { ...prev, showUserForm: true };
            });
          }}
        />
      </div>

      {showUserForm && (
        <div className="my-5 animate-fadeRight">
          <div className="w-full flex justify-center items-center">
            <div className="p-3 bg-blue-600 -mt-4 text-white font-bold rounded ">
              User
            </div>
          </div>
          <UserForm
            setData={(data: RequestRegisterCompany) => {
              setdata(data);
              setShowForm((prev: showFormTypes) => {
                return { ...prev, showDepartmentForm: true };
              });
            }}
          />
        </div>
      )}
      {showDepartmentForm && (
        <div className="my-10 animate-fadeRight">
          <div className="w-full flex justify-center items-center">
            <div className="p-3 bg-blue-600 -mt-4 text-white font-bold rounded ">
              Default Department
            </div>
          </div>
          <DepartemtForm
            setData={(data: RequestRegisterCompany) => {
              setdata(data);
              setShowForm((prev: showFormTypes) => {
                return { ...prev, showRegisterQuestionForm: true };
              });
            }}
          />
        </div>
      )}
      {showRegisterQuestionForm && (
        <div className="my-10 animate-fadeRight">
          <div className="w-full flex justify-center items-center">
            <div className="p-3 bg-blue-600 -mt-4 text-white font-bold rounded ">
              Register Chat Bot Questions
            </div>
          </div>
          <RegisterQuestionsForm data={data} />
        </div>
      )}
      <div ref={div} />
    </div>
  );
};

export default RegisterCompany;
