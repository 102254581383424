import React, { DetailedHTMLProps } from 'react';

export interface selectProps
  extends DetailedHTMLProps<
    React.SelectHTMLAttributes<HTMLSelectElement>,
    HTMLSelectElement
  > {
  name: string;
  error?: string;
  title?: string;
  labelClassName?: string;
  className?: string;
  selectClassName?: string;
  selectErrorClassName?: string;
  ErrorMessageClassName?: string;
  data: any[];
}

const Select: React.FC<selectProps> = ({
  data = [],
  error,
  labelClassName,
  selectClassName,
  selectErrorClassName,
  className,
  name,
  title,
  ...rest
}) => {
  return (
    <div className={className}>
      <label
        className={`block pb-1 text-left font-bold text-black ${labelClassName}`}
        htmlFor={name}
      >
        {title}
      </label>
      <select
        {...rest}
        className={`w-full border-gray-300  text-black ${
          error && `${selectErrorClassName ?? 'focus:border-red-600'}`
        } border ${selectClassName} `}
      >
        <option value="" selected></option>
        {data.map(({ text, value }, key) => (
          <option key={key} value={value}>
            {text}
          </option>
        ))}
      </select>

      <p
        className={` ${
          selectErrorClassName ??
          'text-xs h-4 p-2 w-full text-center  text-red-600'
        }`}
      >
        {error && error}
      </p>
    </div>
  );
};

export default Select;
