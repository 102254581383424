import api from './api';
import { paginationType, RequestRegisterUser } from 'types/api/user';
export default {
  user: (id: number) => api.get('/admin/user/' + id),
  users: (data?: paginationType) => api.get('/admin/user', data),
  registerUser: (data: RequestRegisterUser) => api.post('/admin/user', data),
  deleteUser: (id: number) => api.delete('/admin/user/' + id),
  updateUser: (data: RequestRegisterUser, id: number) =>
    api.put(`/admin/user/${id}`, data),
};
