import * as yup from 'yup';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, FieldError, useForm } from 'react-hook-form';

import { Button, Card, Input, LoadingPage, TextArea } from 'components';

import { RequestRegisterCompany } from 'types/api/company';
import { useMutation, useQuery } from 'react-query';
import gate from 'gate';
import { toast } from 'react-toastify';
import { useHistory, useLocation } from 'react-router-dom';

const DepartmentForm: React.FC = () => {
  const { t } = useTranslation();
  const router = useHistory();

  const location = useLocation();
  const language = new URLSearchParams(location.search).get('languageId');
  const { data, isLoading: isFetching } = useQuery(
    language!,
    async () => await gate.defaultRegisterQuestion(language!),
  );
  const schema = yup.object().shape({
    registerSuccess: yup.string().required(t('field is required')),
    department: yup.string().required(t('field is required')),
    firstname: yup.string().required(t('field is required')),
    lastname: yup.string().required(t('field is required')),
    password: yup.string().required(t('field is required')),
    startTime: yup.string().required(t('field is required')),
    incorrectEmail: yup.string().required(t('field is required')),
    email: yup.string().required(t('field is required')),
    welcome: yup.string().required(t('field is required')),
  });
  const { isLoading, mutate } = useMutation(async ({ data }: any) => {
    try {
      await gate.newDefaultRegisterQuestion(data, language as string);
      toast.success(t('created successfully'));
      router.goBack();
    } catch (error) {
      console.log('error:', error);
    }
  });
  const defaultValues: RequestRegisterCompany['registerQuestions'] = {
    registerSuccess: '',
    department: '',
    firstname: '',
    lastname: '',
    startTime: '',
    password: '',
    confirmPassword: '',
    languageId: Number(language ?? 0),
    incorrectEmail: '',
    email: '',
    welcome: '',
  };

  const { control, setValue, handleSubmit, errors } = useForm({
    resolver: yupResolver(schema),
    defaultValues,
  });
  useEffect(() => {
    if (data) {
      setValue('department', data.department!);
      setValue('confirmPassword', data.confirmPassword!);
      setValue('firstname', data.firstname!);
      setValue('lastname', data.lastname!);
      setValue('password', data.password!);
      setValue('registerSuccess', data.registerSuccess!);
      setValue('welcome', data.welcome!);
      setValue('startTime', data.startTime!);
      setValue('incorrectEmail', data.incorrectEmail!);
      setValue('email', data.email!);
    }
  }, [data, isFetching]);

  const onSubmit = (_data: RequestRegisterCompany['registerQuestions']) => {
    mutate({
      data: { ..._data, confirmPassword: '....' },
      language: language,
    });
  };

  if (isFetching) {
    return <LoadingPage />;
  }
  return (
    <div className="flex w-full  flex-col   px-2">
      <div className=" bg-blue-600 text-white font-bold px-5 py-2 rounded-br-none rounded-bl-none rounded-md shadow-md">
        {t('Add Register Questions')}
      </div>
      <Card className="bg-white w-full">
        <div className="flex flex-col w-full justify-center">
          <form
            className="p-4 grid md:grid-cols-2 "
            onSubmit={handleSubmit(onSubmit)}
          >
            <Controller
              as={TextArea}
              className="p-2"
              textAreaClassName="p-2 rounded-md"
              title={t('First Name')}
              name="firstname"
              control={control}
              error={((errors.firstname as unknown) as FieldError)?.message}
            />
            <Controller
              as={TextArea}
              className="p-2"
              textAreaClassName="p-2 rounded-md"
              title={t('Last Name')}
              name="lastname"
              control={control}
              error={((errors.lastname as unknown) as FieldError)?.message}
            />
            <Controller
              as={TextArea}
              className="p-2"
              textAreaClassName="p-2 rounded-md"
              title={t('Welcome')}
              name="welcome"
              control={control}
              error={((errors.welcome as unknown) as FieldError)?.message}
            />
            <Controller
              className="p-2"
              as={TextArea}
              textAreaClassName="p-2 rounded-md"
              title={t('Password')}
              name="password"
              control={control}
              error={((errors.password as unknown) as FieldError)?.message}
            />
            <Controller
              className="p-2"
              as={TextArea}
              textAreaClassName="p-2 rounded-md"
              title={t('Start Time')}
              name="startTime"
              control={control}
              error={((errors.startTime as unknown) as FieldError)?.message}
            />
            <Controller
              className="p-2"
              as={TextArea}
              textAreaClassName="p-2 text-left rounded-md"
              title={t('Register Success')}
              name="registerSuccess"
              control={control}
              error={
                ((errors.registerSuccess as unknown) as FieldError)?.message
              }
            />
            <Controller
              as={TextArea}
              className="p-2"
              textAreaClassName="p-2 rounded-md"
              title={t('Email')}
              name="email"
              control={control}
              error={((errors.email as unknown) as FieldError)?.message}
            />
            <Controller
              className="p-2"
              as={TextArea}
              textAreaClassName="p-2 rounded-md"
              title={t('Incorrect Email')}
              name="incorrectEmail"
              control={control}
              error={
                ((errors.incorrectEmail as unknown) as FieldError)?.message
              }
            />
            <Controller
              className="p-2"
              as={TextArea}
              textAreaClassName="p-2 rounded-md"
              title={t('Department')}
              name="department"
              control={control}
              error={((errors.department as unknown) as FieldError)?.message}
            />

            <div className="md:col-span-2 py-2 flex justify-center items-center">
              <Button
                isLoading={isLoading}
                className="bg-blue-600 text-white font-bold uppercase p-3"
                title={t('save')}
                type="submit"
              />
            </div>
          </form>
        </div>
      </Card>
    </div>
  );
};

export default DepartmentForm;
