import React from 'react';
import * as yup from 'yup';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { useMutation } from 'react-query';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm, FieldError } from 'react-hook-form';

import gate from 'gate';
import { Button, Input } from 'components';

import { login } from 'store/reducers/user';
import { LoginRequest } from 'types/api/auth';

const Auth: React.FC = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const schema = yup.object().shape({
    email: yup
      .string()
      .email(t('invalid email'))
      .required(t('field is required')),
    password: yup
      .string()
      .required(t('field is required'))
      .min(6, t('short password error')),
  });
  const { isLoading, mutate: Login } = useMutation(
    async (data: LoginRequest) => {
      try {
        const res = await gate.login(data);
        localStorage.setItem('token', (res as any).accessToken);
        dispatch(login({ loggedIn: true }));
      } catch ({ data }) {
        toast.error(data.detail.message);
        toast.error(data?.message);
      }
    },
  );

  const { control, errors, handleSubmit } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      email: '',
      password: '',
    },
  });
  const onSubmit = async (data: LoginRequest) => {
    Login(data);
  };

  return (
    <div className=" w-full min-h-screen bg-blue-350 flex justify-center items-center">
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="lg:w-1/3 w-full shadow-small bg-white p-3"
      >
        <Controller
          as={Input}
          className="w-full p-3"
          name="email"
          type="email"
          error={((errors.email as unknown) as FieldError)?.message}
          labelClassName="pb-2"
          inputClassName="rounded-lg p-2 border-gray-300 border shadow w-full"
          title={t('Email')}
          control={control}
        />
        <Controller
          as={Input}
          className="w-full p-3 pt-0"
          name="password"
          type="password"
          error={((errors.password as unknown) as FieldError)?.message}
          labelClassName="pb-2"
          inputClassName="rounded-lg p-2 border-gray-300 border shadow w-full"
          title={t('Password')}
          control={control}
        />

        <div className="w-full justify-center  flex items-center pt-2 mb-3">
          <Button
            type="submit"
            className="bg-blue-600 uppercase text-sm font-semibold px-5 text-white"
            isLoading={isLoading}
            title={t('login')}
          />
        </div>

        <div className="w-full justify-center  flex items-center mt-1 mb-3">
          <Link
            to="/forget-password"
            className="btn  focus:outline-none text-sm "
          >
            {t('Forget password')}
          </Link>
        </div>
      </form>
    </div>
  );
};

export default Auth;
