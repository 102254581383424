import { RouteProps } from 'react-router-dom';
import { FunctionComponent, SVGProps } from 'react';
// import { ReactComponent as MenuIcon } from 'assets/svg/icons/app.svg';
import { ReactComponent as UsersIcon } from 'assets/svg/icons/group.svg';
import { ReactComponent as CompaniesIcon } from 'assets/svg/icons/enterprise.svg';
import { ReactComponent as SettingIcon } from 'assets/svg/icons/settings-svgrepo-com.svg';
import { ReactComponent as TipIcon } from 'assets/svg/icons/tip.svg';
import { ReactComponent as LanguageIcon } from 'assets/svg/icons/language.svg';
// import Dashboard from './dashboard';
import Users from './users';
import Companies from './companies';
import Settings from './settings';
import Tips from './tips';
import Company from './company';
import NewCompany from './newCompany';
import User from './user';
import UpdateCompany from './updateCompany';
import RegisterQuestions from './registerQuestion';
import Languages from './languages';

interface RoutesType extends RouteProps {
  Component: React.FC<{}>;
  title?: string;
  icon?: FunctionComponent<SVGProps<SVGSVGElement>>;
  isNavBar?: boolean;
}

const routes: RoutesType[] = [
  {
    title: 'Users',
    path: '/users',
    Component: Users,
    component: Users,
    icon: UsersIcon,
    isNavBar: true,
  },

  {
    path: '/register-questions/:action/:id',
    Component: RegisterQuestions,
    component: RegisterQuestions,
  },
  {
    path: '/update-company/:id',
    Component: UpdateCompany,
    component: UpdateCompany,
  },
  {
    path: '/user/:action/:id',
    Component: User,
    component: User,
  },
  {
    path: '/company/:id',
    Component: Company,
    component: Company,
  },
  {
    path: '/new-company',
    Component: NewCompany,
    component: NewCompany,
  },

  {
    title: 'Companies',
    path: '/componies',
    Component: Companies,
    component: Companies,
    icon: CompaniesIcon,
    isNavBar: true,
  },
  {
    title: 'Tips',
    path: '/tips',
    Component: Tips,
    component: Tips,
    icon: TipIcon,
    isNavBar: true,
  },
  {
    title: 'Settings',
    path: '/settings',
    Component: Settings,
    component: Settings,
    icon: SettingIcon,
    isNavBar: true,
  },
  {
    path: '/languages',
    Component: Languages,
    component: Languages,
    isNavBar: true,
    title: 'languages',
    icon: LanguageIcon,
  },
];

export default routes;
