import api from './api';

import { LanguageType, ResponseLanguage } from 'types/api/language';

import { paginationType } from 'types/api/user';

export default {
  newLanguage: (data: LanguageType) => api.post('/admin/language', data),
  getLanguages: (data?: paginationType) =>
    api.get<ResponseLanguage[]>('/admin/language'),
  updateLanguage: (id: number | string, data: LanguageType) =>
    api.put('/admin/language/' + id, data),
  deleteLanguage: (id: number | string) => api.delete('/admin/language/' + id),
};
