import React, { useContext, useLayoutEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import menuContext from 'context/navbarContext';
import routes from 'pages/_routes';

const SideBar: React.FC = () => {
  const { showMenu } = useContext(menuContext);
  const [isMobile, setIsMobile] = useState<boolean>(false);
  useLayoutEffect(() => {
    if (window.screen.width < 700) {
      setIsMobile(true);
    }
  }, []);

  useLayoutEffect(() => {
    window.addEventListener('resize', () => {
      if (window.screen.width > 700) {
        setIsMobile(false);
      } else {
        setIsMobile(true);
      }
    });
  }, [window.screen.width]);

  return (
    <div
      style={{
        height: !isMobile ? 'auto' : showMenu ? routes.length * 59 : 0,
      }}
      className="lg:h-auto lg:px-20 border-b border-gray-300  transition-all duration-300 overflow-hidden flex justify-start"
    >
      <div className="flex flex-col lg:flex-row">
        {routes.map(({ icon: Icon, title, path, isNavBar }) => {
          if (isNavBar && !!Icon)
            return (
              <Link
                key={title}
                to={String(path)}
                className="text-gray-700  items-center flex font-bold w-full py-4 pr-3 hover:bg-gray-100 transition-all duration-300 "
              >
                <Icon fill="gray" className="mx-3 w-4 h-4" />
                {title}
              </Link>
            );
        })}
      </div>
    </div>
  );
};

export default SideBar;
