import * as yup from 'yup';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, FieldError, useForm } from 'react-hook-form';

import { Button, Card, Input } from 'components';

import { RequestRegisterCompany } from 'types/api/company';
import DatePicker, { DayValue } from 'react-modern-calendar-datepicker';
interface Prop {
  setData: Function;
}
const UserForm: React.FC<Prop> = ({ setData }) => {
  const [selectedDay, setSelectedDay] = useState<DayValue>();
  const { t } = useTranslation();
  const schema = yup.object().shape({
    email: yup
      .string()
      .email(t('invalid email'))
      .required(t('field is required')),
    firstName: yup.string().required(t('field is required')),
    lastName: yup.string().required(t('field is required')),
    password: yup.string().required(t('field is required')),
    startTime: yup.string().required(t('field is required')),
  });

  const defaultValues: RequestRegisterCompany['user'] = {
    firstName: '',
    password: '',
    email: '',
    lastName: '',
    startTime: '',
  };
  const { control, handleSubmit, errors } = useForm({
    defaultValues,
    resolver: yupResolver(schema),
  });

  const onSubmit = (data: RequestRegisterCompany['user']) => {
    setData((prev: RequestRegisterCompany) => {
      return {
        ...prev,
        user: {
          ...data,
          startTime: new Date(
            `${selectedDay?.year}/${selectedDay?.month}/${selectedDay?.day}`,
          ),
        },
      };
    });
  };
  return (
    <div className="flex w-full  flex-col   px-2">
      <Card className="bg-white w-full">
        <div className="flex flex-col w-full justify-center">
          <form
            className="p-4 grid md:grid-cols-2 lg:grid-cols-3"
            onSubmit={handleSubmit(onSubmit)}
          >
            <Controller
              as={Input}
              className="m-2"
              control={control}
              name="firstName"
              inputClassName="p-2 rounded-md"
              title={t('First Name')}
              error={((errors.firstName as unknown) as FieldError)?.message}
            />
            <Controller
              as={Input}
              className="m-2"
              control={control}
              name="lastName"
              inputClassName="p-2 rounded-md"
              title={t('Last Name')}
              error={((errors.lastName as unknown) as FieldError)?.message}
            />
            <Controller
              as={Input}
              className="m-2"
              control={control}
              name="email"
              inputClassName="p-2 rounded-md"
              title={t('Email')}
              type="email"
              error={((errors.email as unknown) as FieldError)?.message}
            />
            <Controller
              as={Input}
              className="m-2"
              control={control}
              name="password"
              type="password"
              inputClassName="p-2 rounded-md"
              title={t('Password')}
              error={((errors.password as unknown) as FieldError)?.message}
            />

            <div className="m-2">
              <label className={`block pb-1 text-left font-bold text-black `}>
                {t('Start Time')}
              </label>

              <Controller
                name="startTime"
                control={control}
                render={({ onChange }: any) => (
                  <DatePicker
                    wrapperClassName="w-full"
                    value={selectedDay}
                    onChange={(e) => {
                      setSelectedDay(e);
                      onChange(e);
                    }}
                    colorPrimary="orange"
                    renderInput={({ ref }: any) => (
                      <input
                        readOnly
                        className={`w-full border-gray-300 p-2 rounded-md   text-black ${
                          errors.startTime && 'focus:border-red-600'
                        }  border`}
                        ref={ref}
                        value={
                          selectedDay
                            ? `${selectedDay.year}/${selectedDay.month}/${selectedDay.day}`
                            : ''
                        }
                      />
                    )}
                  />
                )}
              />

              <p className="text-xs h-4 p-2 w-full text-center  text-red-600">
                {((errors.startTime as unknown) as FieldError)?.message &&
                  ((errors.startTime as unknown) as FieldError)?.message}
              </p>
            </div>
            <div className="w-full pt-2 pl-4 md:col-span-2 lg:col-span-3 flex justify-end items-center">
              <Button
                type="submit"
                className="bg-blue-600 text-sm px-3 font-semibold text-white"
              >
                {t('Save')}
              </Button>
            </div>
          </form>
        </div>
      </Card>
    </div>
  );
};

export default UserForm;
