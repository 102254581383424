import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Spinner,
  Table,
  TableBody,
  TableCell,
  TableHead,
} from 'components';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import gate from 'gate';
import LoadingPage from 'components/LoadingPage';
import { Link } from 'react-router-dom';
import { getDownloadAddress } from 'utils';

const Companies: React.FC = () => {
  const cache = useQueryClient();
  const { t } = useTranslation();
  const [_id, setId] = useState<number>(0);
  const { data, isLoading } = useQuery(
    'companies',
    async () => await gate.companies(),
  );

  const { isLoading: deleting, mutate: _onDelete } = useMutation(
    async (id: number) => {
      try {
        await gate.deleteCompany(id);
        cache.invalidateQueries('companies');
      } catch (err) {}
    },
  );
  const onDelete = (id: number) => {
    if (!window.confirm('are you sure ')) return;
    setId(id);
    _onDelete(id);
  };
  if (isLoading) {
    return <LoadingPage />;
  }

  return (
    <div className="flex flex-col  justify-start overflow-hidden w-full min-h-screen ">
      <div className="flex justify-end items-center my-2">
        <Link to="/new-company">
          <Button className="font-black  border w-16 h-10 flex justify-center items-center focus:border-blue-600 text-black rounded-lg text-3xl">
            +
          </Button>
        </Link>
      </div>
      <Table>
        <TableHead
          data={[
            t('name') as string,
            t('bot name bat avatar') as string,
            t('url') as string,
            t('color') as string,
          ]}
        />
        <TableBody>
          {(data as any[])?.map(
            ({ botAvatar, botName, color, id, logo, name, url }) => (
              <tr key={id}>
                <TableCell>
                  <div className="flex items-center">
                    <div className="flex-shrink-0 h-10 w-10">
                      <img
                        className="h-10 w-10 rounded-full"
                        src={getDownloadAddress(logo)}
                        alt=""
                      />
                    </div>
                    <div className="ml-4">
                      <div className="text-sm font-medium text-gray-900">
                        {name}
                      </div>
                      <div className="text-sm text-gray-500">{url}</div>
                    </div>
                  </div>
                </TableCell>
                <TableCell>
                  <div className="flex items-center">
                    <div className="flex-shrink-0 h-10 w-10">
                      <img
                        className="h-10 w-10 rounded-full"
                        src={getDownloadAddress(botAvatar)}
                        alt=""
                      />
                    </div>
                    <div className="ml-4">
                      <div className="text-sm font-medium text-gray-900">
                        {botName}
                      </div>
                    </div>
                  </div>
                </TableCell>
                <TableCell>
                  <a
                    href={`https://headstart.work/${url}/register`}
                    className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 "
                  >
                    {url}
                  </a>
                </TableCell>
                <TableCell>
                  <span
                    style={{ background: color }}
                    className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 "
                  >
                    {color}
                  </span>
                </TableCell>

                <TableCell>
                  <Link
                    to={'/update-company/' + id}
                    className="text-indigo-600 hover:text-indigo-900"
                  >
                    {t('Edit')}
                  </Link>
                  {/* <Link to={`company/${id}`}>
                    <Button className="text-indigo-600 hover:text-indigo-900">
                      {t('Details')}
                    </Button>
                  </Link> */}

                  <Button
                    onClick={() => onDelete(id)}
                    isLoading={deleting && _id === id}
                    spinnerColor="red"
                    className="text-red-500 mx-4 hover:text-indigo-900"
                  >
                    Delete
                  </Button>
                </TableCell>
              </tr>
            ),
          )}
        </TableBody>
      </Table>
    </div>
  );
};

export default Companies;
