import {
  ChangePasswordRequest,
  ForgetPasswordRequest,
  LoginRequest,
} from 'types/api/auth';
import api from './api';

export default {
  me: () => api.get('/staff/me'),
  login: (data: LoginRequest) => api.post('/admin/login', data),
  forgetPassword: (data: ForgetPasswordRequest) =>
    api.post('/admin/forget-password-request', data),
  changePassword: (data: ChangePasswordRequest) =>
    api.post('/admin/forget-password', data),
};
