import * as yup from 'yup';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SketchPicker } from 'react-color';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, FieldError, useForm } from 'react-hook-form';

import { Button, Card, Cropper, DropDown, Input, Spinner } from 'components';

import { RequestRegisterCompany } from 'types/api/company';
import { ReactComponent as UserIcon } from 'assets/svg/icons/user.svg';
import { ResponseUploadFile } from 'types/api/default';
import { getDownloadAddress } from 'utils';
interface Prop {
  setData: Function;
}
const CompanyForm: React.FC<Prop> = ({ setData }) => {
  const [logo, setLogo] = useState<ResponseUploadFile>({
    url: '',
    filename: '',
  });
  const [logoFile, setLogoFile] = useState('');
  const [botAvatar, setBotAvatar] = useState<ResponseUploadFile>({
    url: '',
    filename: '',
  });
  const [avatarFile, setAvatarFile] = useState('');

  const { t } = useTranslation();
  const schema = yup.object().shape({
    name: yup.string().required(t('field is required')),
    url: yup.string().required(t('field is required')),
    botName: yup.string().required(t('field is required')),
    logo: yup.mixed().test('require', t('field is required'), (value) => {
      if (!(value as FileList)[0]) return false;
      return true;
    }),
    botAvatar: yup.mixed().test('require', t('field is required'), (value) => {
      if (!(value as FileList)[0]) return false;
      return true;
    }),
  });
  const [color, setColor] = useState<string>('blue');
  const [visible, setVisible] = useState<boolean>(false);

  const defaultValues: RequestRegisterCompany['company'] = {
    url: '',
    logo: '',
    color: '',
    botAvatar: '',
    name: '',
    botName: '',
  };
  const { control, handleSubmit, errors, register } = useForm({
    resolver: yupResolver(schema),
    defaultValues,
  });

  const onSubmit = (data: RequestRegisterCompany['company']) => {
    const _data: RequestRegisterCompany['company'] = {
      ...data,
      color,
      botAvatar: botAvatar.filename,
      logo: logo.filename,
    };

    setData((prev: RequestRegisterCompany) => {
      return { ...prev, company: _data };
    });
  };
  return (
    <div className="flex w-full  flex-col   px-2">
      <Card className="bg-white w-full">
        <div className="flex flex-col w-full justify-center">
          <form
            className="p-4 grid md:grid-cols-2 lg:grid-cols-3"
            onSubmit={handleSubmit(onSubmit)}
          >
            <Controller
              as={Input}
              className="m-2"
              control={control}
              name="name"
              inputClassName="p-2 rounded-md"
              title={t('Name')}
              error={((errors.name as unknown) as FieldError)?.message}
            />
            <Controller
              as={Input}
              className="m-2"
              control={control}
              name="url"
              inputClassName="p-2 rounded-md"
              title={t('Url')}
              error={((errors.url as unknown) as FieldError)?.message}
            />
            <Controller
              as={Input}
              className="m-2"
              control={control}
              name="botName"
              type="text"
              inputClassName="p-2 rounded-md"
              title={t('Bot Name')}
              error={((errors.botName as unknown) as FieldError)?.message}
            />

            <div className="mx-2">
              <label className="block pb-1 text-left font-bold text-black">
                {t('Bot Avatar')}
              </label>
              <div className="mt-1 flex items-center">
                <span className="inline-block h-12 w-12 rounded-full overflow-hidden bg-gray-100">
                  {!botAvatar.url ? (
                    <UserIcon />
                  ) : (
                    <img
                      className="object-cover"
                      src={getDownloadAddress(botAvatar.url)}
                      alt={botAvatar.filename}
                    />
                  )}
                </span>
                <input
                  ref={register}
                  className="hidden"
                  type="file"
                  onChange={(e) => setAvatarFile(e as any)}
                  accept="image/*"
                  name="botAvatar"
                  id="avatar"
                />

                <label
                  className="ml-5 bg-white py-2 px-3 border border-gray-300 rounded-md shadow-sm text-sm leading-4 font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  htmlFor="avatar"
                >
                  {t('Change')}
                </label>
              </div>

              <p className="text-xs h-4 p-2 w-full text-center  text-red-600">
                {((errors.botAvatar as unknown) as FieldError)?.message}
              </p>
            </div>
            <div className="mx-2">
              <label className="block pb-1 text-left font-bold text-black">
                {t('Logo')}
              </label>
              <div className="mt-1 flex items-center">
                <span className="inline-block h-12 w-12 rounded-full overflow-hidden bg-gray-100">
                  {!logo.url ? (
                    <UserIcon />
                  ) : (
                    <img
                      className="object-cover"
                      src={getDownloadAddress(logo.url)}
                      alt={logo.filename}
                    />
                  )}
                </span>
                <input
                  ref={register}
                  className="hidden"
                  type="file"
                  onChange={(e) => setLogoFile(e as any)}
                  accept="image/*"
                  name="logo"
                  id="logo"
                />

                <label
                  className="ml-5 bg-white py-2 px-3 border border-gray-300 rounded-md shadow-sm text-sm leading-4 font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  htmlFor="logo"
                >
                  {t('Change')}
                </label>
              </div>
              <p className="text-xs h-4 p-2 w-full text-center  text-red-600">
                {((errors.logo as unknown) as FieldError)?.message}
              </p>
            </div>
            <div className="mx-2">
              <label className="block pb-1 text-left font-bold text-black">
                {t('Color')}
              </label>
              <div className="mt-1 flex items-center">
                <span
                  style={{ backgroundColor: color }}
                  className="inline-block h-12 w-12 rounded-full overflow-hidden "
                ></span>

                <Button
                  onClick={(e) => {
                    e.preventDefault();
                    setVisible(true);
                  }}
                  className="ml-5 bg-white py-2 px-3 border border-gray-300 rounded-md shadow-sm text-sm leading-4 font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                >
                  {t('Change')}
                </Button>

                <DropDown
                  className=" -ml-32 -mt-64"
                  visible={visible}
                  onClose={() => setVisible(false)}
                >
                  <SketchPicker
                    color={color}
                    onChangeComplete={({ hex }) => setColor(hex)}
                  />
                </DropDown>
              </div>
            </div>

            <div className="w-full pt-2 pl-4 md:col-span-2 lg:col-span-3 flex justify-end items-center">
              <Button
                type="submit"
                className="bg-blue-600 text-sm px-3 font-semibold text-white"
              >
                {t('Save')}
              </Button>
            </div>
          </form>
        </div>
      </Card>

      <Cropper file={logoFile} setResponse={(res) => setLogo(res)} />
      <Cropper file={avatarFile} setResponse={(res) => setBotAvatar(res)} />
    </div>
  );
};

export default CompanyForm;
