import React from 'react';
type Atomic = string | number;
interface TeableHeadProps {
  data: Atomic[];
}

const TeableHead: React.FC<TeableHeadProps> = ({ data }) => {
  let id: number = 0;
  return (
    <thead>
      <tr>
        {data.map((title: Atomic) => {
          id++;
          return (
            <th
              key={id}
              scope="col"
              className="px-6 py-3 whitespace-no-wrap bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
            >
              {title}
            </th>
          );
        })}
      </tr>
    </thead>
  );
};

export default TeableHead;
