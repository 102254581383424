import { Button, Card, Input, LoadingPage, Select } from 'components';
import gate from 'gate';
import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { Link, useHistory } from 'react-router-dom';

const Settings: FC = () => {
  const [languageId, setLanguageId] = useState<string | number>(0);

  const { data, isLoading } = useQuery(
    'languages',
    async () => await gate.getLanguages(),
  );

  const { t } = useTranslation();
  const router = useHistory();

  if (isLoading) {
    return <LoadingPage />;
  }

  console.log('languageId:', languageId);
  return (
    <Card className="bg-white p-4">
      <div className="w-full flex flex-col lg:flex-row ">
        <Select
          className="w-1/2"
          data={
            data?.map(({ name, id }) => {
              return {
                text: name,
                value: id,
              };
            }) ?? []
          }
          onChange={(e) => setLanguageId(e.target.value)}
          name="language"
          defaultValue={data && data[0].id}
          title={t('Language')}
          selectClassName="p-2 rounded"
        />
        <Button
          className="w-2/3"
          // disabled={!!languageId}
          onClick={() =>
            router.push(`/register-questions/new/new?languageId=${languageId}`)
          }
        >
          {t('Add default reguster questions')}
        </Button>
      </div>
    </Card>
  );
};

export default Settings;
