import * as yup from 'yup';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SketchPicker } from 'react-color';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, FieldError, useForm } from 'react-hook-form';

import {
  Button,
  Card,
  Cropper,
  DropDown,
  Input,
  Select,
  Spinner,
} from 'components';

import { RequestRegisterCompany } from 'types/api/company';
import { ReactComponent as UserIcon } from 'assets/svg/icons/user.svg';
import { ResponseUploadFile } from 'types/api/default';
import { getDownloadAddress } from 'utils';
import { useQuery } from 'react-query';
import gate from 'gate';
interface Prop {
  setData: Function;
}
type colorObj = {
  accentColor: string;
  mainColor: string;
  backgroundColor: string;
};
type dropDownObj = {
  accentColorVisible: boolean;
  mainColorVisible: boolean;
  backgroundColorVisible: boolean;
};
const DepartmentForm: React.FC<Prop> = ({ setData }) => {
  const [headerFile, setHeaderFile] = useState<any>('');
  const { data, isLoading } = useQuery('languages', async () =>
    gate.getLanguages(),
  );
  const [header, setHeader] = useState<ResponseUploadFile>({
    filename: '',
    url: '',
  });
  const { t } = useTranslation();
  const schema = yup.object().shape({
    name: yup.string().required(t('field is required')),
    language: yup.string().required(t('field is required')),
    isPublished: yup.string().required(t('field is required')),
    header: yup.mixed().test('require', t('field is required'), (value) => {
      if (!(value as FileList)[0]) return false;
      return true;
    }),
  });
  const [{ accentColor, backgroundColor, mainColor }, setColor] = useState<
    colorObj
  >({ accentColor: 'yellow', mainColor: 'black', backgroundColor: 'yellow' });
  const [
    { mainColorVisible, backgroundColorVisible, accentColorVisible },
    setVisible,
  ] = useState<dropDownObj>({
    accentColorVisible: false,
    backgroundColorVisible: false,
    mainColorVisible: false,
  });

  const defaultValues: RequestRegisterCompany['department'] = {
    accentColor: '',
    name: '',
    url: '',
    mainColor: '',
    language: '',
    isPublished: true,
    header: '',
    backgroundColor: '',
  };
  const { control, handleSubmit, errors, register } = useForm({
    defaultValues,
    resolver: yupResolver(schema),
  });

  const onSubmit = (data: RequestRegisterCompany['department']) => {
    const _data: RequestRegisterCompany['department'] = {
      ...data,
      mainColor,
      accentColor,
      backgroundColor,
      header: header.filename,
    };

    setData((prev: RequestRegisterCompany) => {
      return {
        ...prev,
        department: _data,
      };
    });
  };

  if (isLoading) {
    return (
      <div className="w-full h-64  flex justify-center items-center">
        <Spinner className="w-32 h-32" />
      </div>
    );
  }
  return (
    <div className="flex w-full  flex-col   px-2">
      <Card className="bg-white w-full">
        <div className="flex flex-col w-full justify-center">
          <form
            className="p-4 grid md:grid-cols-2 lg:grid-cols-3"
            onSubmit={handleSubmit(onSubmit)}
          >
            <Controller
              as={Select}
              className="m-2"
              control={control}
              name="language"
              data={
                data?.map(({ name, id }) => {
                  return { text: name, value: id };
                }) ?? []
              }
              selectClassName="p-2 rounded-md"
              title={t('Language')}
              error={((errors.name as unknown) as FieldError)?.message}
            />
            <Controller
              as={Input}
              className="m-2"
              control={control}
              name="name"
              inputClassName="p-2 rounded-md"
              title={t('Name')}
              error={((errors.name as unknown) as FieldError)?.message}
            />
            <Controller
              as={Input}
              className="m-2"
              control={control}
              name="url"
              inputClassName="p-2 rounded-md"
              title={t('Url')}
              error={((errors.url as unknown) as FieldError)?.message}
            />
            <Controller
              as={Select}
              className="m-2"
              data={[
                { text: 'publish', value: true },
                { text: 'unpublish', value: false },
              ]}
              title={t('Publish')}
              name="isPublished"
              selectClassName="p-2 rounded-md"
              control={control}
              error={((errors.isPublished as unknown) as FieldError)?.message}
            />

            <div className="mx-2">
              <label className="block pb-1 text-left font-bold text-black">
                {t('Header')}
              </label>
              <div className="mt-1 flex items-center">
                <span className="inline-block h-12 w-12 rounded-full overflow-hidden bg-gray-100">
                  {!header.url ? (
                    <UserIcon />
                  ) : (
                    <img
                      className="object-cover"
                      src={getDownloadAddress(header.url)}
                      alt={header.filename}
                    />
                  )}
                </span>
                <input
                  ref={register}
                  className="hidden"
                  type="file"
                  onChange={(e) => setHeaderFile(e as any)}
                  accept="image/*"
                  name="header"
                  id="header"
                />

                <label
                  className="ml-5 bg-white py-2 px-3 border border-gray-300 rounded-md shadow-sm text-sm leading-4 font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  htmlFor="header"
                >
                  {t('Change')}
                </label>
              </div>

              <p className="text-xs h-4 p-2 w-full text-center  text-red-600">
                {((errors.header as unknown) as FieldError)?.message}
              </p>
            </div>

            <div className="mx-2">
              <label className="block pb-1 text-left font-bold text-black">
                {t('Main Color')}
              </label>
              <div className="mt-1 flex items-center">
                <span
                  style={{ backgroundColor: mainColor }}
                  className="inline-block h-12 w-12 rounded-full overflow-hidden "
                ></span>

                <Button
                  onClick={(e) => {
                    e.preventDefault();
                    setVisible((prev: dropDownObj) => {
                      return { ...prev, mainColorVisible: true };
                    });
                  }}
                  className="ml-5 bg-white py-2 px-3 border border-gray-300 rounded-md shadow-sm text-sm leading-4 font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                >
                  {t('Change')}
                </Button>

                <DropDown
                  className=" -ml-32 -mt-64"
                  visible={mainColorVisible}
                  onClose={() =>
                    setVisible((prev: dropDownObj) => {
                      return { ...prev, mainColorVisible: false };
                    })
                  }
                >
                  <SketchPicker
                    color={mainColor}
                    onChangeComplete={({ hex }) =>
                      setColor((prev: colorObj) => {
                        return {
                          ...prev,
                          mainColor: hex,
                        };
                      })
                    }
                  />
                </DropDown>
              </div>
            </div>
            <div className="mx-2">
              <label className="block pb-1 text-left font-bold text-black">
                {t('Accent Color')}
              </label>
              <div className="mt-1 flex items-center">
                <span
                  style={{ backgroundColor: accentColor }}
                  className="inline-block h-12 w-12 rounded-full overflow-hidden "
                ></span>

                <Button
                  onClick={(e) => {
                    e.preventDefault();
                    setVisible((prev: dropDownObj) => {
                      return { ...prev, accentColorVisible: true };
                    });
                  }}
                  className="ml-5 bg-white py-2 px-3 border border-gray-300 rounded-md shadow-sm text-sm leading-4 font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                >
                  {t('Change')}
                </Button>

                <DropDown
                  className=" -ml-32 -mt-64"
                  visible={accentColorVisible}
                  onClose={() =>
                    setVisible((prev: dropDownObj) => {
                      return { ...prev, accentColorVisible: false };
                    })
                  }
                >
                  <SketchPicker
                    color={accentColor}
                    onChangeComplete={({ hex }) =>
                      setColor((prev: colorObj) => {
                        return {
                          ...prev,
                          accentColor: hex,
                        };
                      })
                    }
                  />
                </DropDown>
              </div>
            </div>
            <div className="mx-2">
              <label className="block pb-1 text-left font-bold text-black">
                {t('Background Color')}
              </label>
              <div className="mt-1 flex items-center">
                <span
                  style={{ backgroundColor: backgroundColor }}
                  className="inline-block h-12 w-12 rounded-full overflow-hidden "
                ></span>

                <Button
                  onClick={(e) => {
                    e.preventDefault();

                    setVisible((prev: dropDownObj) => {
                      return { ...prev, backgroundColorVisible: true };
                    });
                  }}
                  className="ml-5 bg-white py-2 px-3 border border-gray-300 rounded-md shadow-sm text-sm leading-4 font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                >
                  {t('Change')}
                </Button>

                <DropDown
                  className=" -ml-32 -mt-64"
                  visible={backgroundColorVisible}
                  onClose={() =>
                    setVisible((prev: dropDownObj) => {
                      return { ...prev, backgroundColorVisible: false };
                    })
                  }
                >
                  <SketchPicker
                    color={mainColor}
                    onChangeComplete={({ hex }) =>
                      setColor((prev: colorObj) => {
                        return {
                          ...prev,
                          backgroundColor: hex,
                        };
                      })
                    }
                  />
                </DropDown>
              </div>
            </div>

            <div className="w-full pt-2 pl-4 md:col-span-2 lg:col-span-3 flex justify-end items-center">
              <Button
                type="submit"
                className="bg-blue-600 text-sm px-3 font-semibold text-white"
              >
                {t('Save')}
              </Button>
            </div>
          </form>
        </div>
      </Card>
      <Cropper file={headerFile} setResponse={(res) => setHeader(res)} />
    </div>
  );
};

export default DepartmentForm;
