import React from 'react';
import * as yup from 'yup';
import { useHistory, useParams } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm, FieldError } from 'react-hook-form';

import { Button, Input } from 'components';
import { useMutation } from 'react-query';
import gate from 'gate';
import { toast } from 'react-toastify';
import { ChangePasswordRequest } from 'types/api/auth';
import { useTranslation } from 'react-i18next';

const ChangePassword: React.FC = () => {
  const { t } = useTranslation();
  const schema = yup.object().shape({
    password: yup
      .string()
      .required(t('field is required'))
      .min(6, t('short password error')),
    repeatPassword: yup
      .string()
      .oneOf([yup.ref('password')], t('Passwords must match')),
  });
  const { token } = useParams<any>();
  const router = useHistory();
  const { errors, handleSubmit, control } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      password: '',
      repeatPassword: '',
    },
  });

  const { isLoading, mutate: changePassword } = useMutation(
    async ({ password }: ChangePasswordRequest) => {
      try {
        const res = await gate.changePassword({ token, password });
        toast.success((res as any).message);
        router.replace('/login');
      } catch ({ data }) {
        toast.error(data.message);
      }
    },
  );

  const onSubmit = (data: ChangePasswordRequest) => {
    changePassword(data);
  };

  return (
    <div className=" w-full min-h-screen bg-blue-350 flex justify-center items-center">
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="lg:w-1/3 w-full shadow-small rounded-lg bg-white p-3"
      >
        <div className="w-full p-3 ">{t('title of change password')}</div>
        <Controller
          as={Input}
          className="w-full p-3"
          name="password"
          type="password"
          error={((errors.password as unknown) as FieldError)?.message}
          labelClassName="pb-2"
          inputClassName="rounded-lg p-2 border-gray-300 border shadow w-full"
          title={t('New Password')}
          control={control}
        />
        <Controller
          as={Input}
          className="w-full p-3"
          name="repeatPassword"
          type="password"
          error={((errors.repeatPassword as unknown) as FieldError)?.message}
          labelClassName="pb-2"
          inputClassName="rounded-lg p-2 border-gray-300 border shadow w-full"
          title={t('Confirm Password')}
          control={control}
        />

        <div className="w-full justify-center  flex items-center pt-2 mb-3">
          <Button
            isLoading={isLoading}
            type="submit"
            className="bg-blue-600 text-sm font-medium px-5 text-white"
            title={t('Reset password')}
          />
        </div>
      </form>
    </div>
  );
};

export default ChangePassword;
