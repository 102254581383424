import * as yup from 'yup';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, FieldError, useForm } from 'react-hook-form';

import { Button, Card, Select, Spinner, TextArea } from 'components';

import { RequestRegisterCompany } from 'types/api/company';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import gate from 'gate';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
interface Prop {
  data: RequestRegisterCompany | undefined;
}
const DepartmentForm: React.FC<Prop> = ({ data }) => {
  const { t } = useTranslation();
  const cache = useQueryClient();
  const { data: defaultRegisterQuestion, isLoading: isFetching } = useQuery(
    data?.department.language!,
    async () => await gate.defaultRegisterQuestion(data?.department.language!),
  );
  const router = useHistory();
  const schema = yup.object().shape({
    department: yup.string().required(t('field is required')),
    firstname: yup.string().required(t('field is required')),
    lastname: yup.string().required(t('field is required')),
    password: yup.string().required(t('field is required')),
    startTime: yup.string().required(t('field is required')),
    incorrectEmail: yup.string().required(t('field is required')),
    email: yup.string().required(t('field is required')),
    welcome: yup.string().required(t('field is required')),
  });

  const defaultValues: RequestRegisterCompany['registerQuestions'] = {
    confirmPassword: '.....',
    department: '',
    firstname: '',
    lastname: '',
    startTime: '',
    password: '',
    registerSuccess: '',
    languageId: Number(data?.department!.language),
    incorrectEmail: '',
    email: '',
    welcome: '',
  };

  const { control, handleSubmit, setValue, errors } = useForm({
    resolver: yupResolver(schema),
    defaultValues,
  });

  useEffect(() => {
    if (data) {
      setValue('department', defaultRegisterQuestion?.department!);
      setValue('confirmPassword', defaultRegisterQuestion?.confirmPassword!);
      setValue('firstname', defaultRegisterQuestion?.firstname!);
      setValue('lastname', defaultRegisterQuestion?.lastname!);
      setValue('password', defaultRegisterQuestion?.password!);
      setValue('registerSuccess', defaultRegisterQuestion?.registerSuccess!);
      setValue('welcome', defaultRegisterQuestion?.welcome!);
      setValue('startTime', defaultRegisterQuestion?.startTime!);
      setValue('incorrectEmail', defaultRegisterQuestion?.incorrectEmail!);
      setValue('email', defaultRegisterQuestion?.email!);
      setValue('language', data.department.language!);
    }
  }, [defaultRegisterQuestion, isFetching]);

  const { isLoading, mutate: registerCompany } = useMutation(
    async (data: RequestRegisterCompany) => {
      try {
        await gate.registerCompany(data);
        toast.success(t('Successfully Created '));
        cache.invalidateQueries('companies');
        router.goBack();
      } catch (error) {
        console.log('error:', error);
        toast.error(error.data.detail.message);
      }
    },
  );

  const onSubmit = (_data: RequestRegisterCompany['registerQuestions']) => {
    if (data) {
      registerCompany({
        ...data,
        registerQuestions: {
          ..._data,
          languageId: Number(data!.department!.language),
        },
      });
    }
  };
  if (isFetching) {
    return (
      <div className="h-64 w-full flex justify-center items-center ">
        <Spinner color="gray" className="w-32 h-32" />
      </div>
    );
  }
  return (
    <div className="flex w-full  flex-col   px-2">
      <Card className="bg-white w-full">
        <div className="flex flex-col w-full justify-center">
          <form
            className="p-4 grid md:grid-cols-2 "
            onSubmit={handleSubmit(onSubmit)}
          >
            <Controller
              as={TextArea}
              className="p-2"
              textAreaClassName="p-2 rounded-md"
              title={t('First Name')}
              name="firstname"
              control={control}
              error={((errors.firstname as unknown) as FieldError)?.message}
            />
            <Controller
              as={TextArea}
              className="p-2"
              textAreaClassName="p-2 rounded-md"
              title={t('Last Name')}
              name="lastname"
              control={control}
              error={((errors.lastname as unknown) as FieldError)?.message}
            />
            <Controller
              as={TextArea}
              className="p-2"
              textAreaClassName="p-2 rounded-md"
              title={t('Welcome')}
              name="welcome"
              control={control}
              error={((errors.welcome as unknown) as FieldError)?.message}
            />
            <Controller
              className="p-2"
              as={TextArea}
              textAreaClassName="p-2 rounded-md"
              title={t('Password')}
              name="password"
              control={control}
              error={((errors.password as unknown) as FieldError)?.message}
            />
            <Controller
              className="p-2"
              as={TextArea}
              textAreaClassName="p-2 rounded-md"
              title={t('Start Time')}
              name="startTime"
              control={control}
              error={((errors.startTime as unknown) as FieldError)?.message}
            />

            <Controller
              as={TextArea}
              className="p-2"
              textAreaClassName="p-2 rounded-md"
              title={t('Email')}
              name="email"
              control={control}
              error={((errors.email as unknown) as FieldError)?.message}
            />
            <Controller
              className="p-2"
              as={TextArea}
              textAreaClassName="p-2 rounded-md"
              title={t('Incorrect Email')}
              name="incorrectEmail"
              control={control}
              error={
                ((errors.incorrectEmail as unknown) as FieldError)?.message
              }
            />
            <Controller
              className="p-2"
              as={TextArea}
              textAreaClassName="p-2 rounded-md"
              title={t('Department')}
              name="department"
              control={control}
              error={((errors.department as unknown) as FieldError)?.message}
            />

            <div className="md:col-span-2 py-2 flex justify-center items-center">
              <Button
                isLoading={isLoading}
                className="bg-blue-600 text-white font-bold uppercase p-3"
                title={t('save')}
                type="submit"
              />
            </div>
          </form>
        </div>
      </Card>
    </div>
  );
};

export default DepartmentForm;
