import React from 'react';
import { Redirect, Route, Router, Switch } from 'react-router-dom';
import { SidebarContextProvider } from 'context/navbarContext';
import { useSelector } from 'react-redux';

import { selectUser } from 'store/selectors/user';
import { Layout } from 'components';

import routes from './_routes';
import Auth from './auth';
import ForgetPassword from './forget-password';
import ChangePassword from './change-password';
const Routes: React.FC = () => {
  const { loggedIn } = useSelector(selectUser);

  if (!loggedIn) {
    return (
      <>
        <Switch>
          <Route path="/login" component={Auth} />
          <Route path="/forget-password" component={ForgetPassword} />
          <Route path="/change-password/:token" component={ChangePassword} />
          <Redirect to="/login" />
        </Switch>
      </>
    );
  }
  return (
    <SidebarContextProvider>
      <Layout>
        <Switch>
          {routes.map(({ Component, path, exact, render }) => (
            <Route
              exact={exact}
              key={String(path)}
              // component={component}
              path={path}
              render={render}
            >
              <div className="animate-fadeRight w-full ">
                <Component />
              </div>
            </Route>
          ))}
          <Redirect to="/dashboard" />
        </Switch>
      </Layout>
    </SidebarContextProvider>
  );
};

export default Routes;
