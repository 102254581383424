import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  Button,
  Spinner,
} from 'components';
import { useInfiniteQuery, useMutation, useQueryClient } from 'react-query';
import { useTranslation } from 'react-i18next';

import gate from 'gate';

import AvataPlaceHolder from 'assets/images/avatar-placeholder.png';
import { useIntersectionObserver } from 'hooks/use-intersection-observer';
import { getDownloadAddress } from 'utils';
type paginationDetailsType = {
  page: number;
  pageCount: number | null;
};
export interface DashboardProps {}

const Users: React.FC<DashboardProps> = () => {
  const div = useRef<HTMLDivElement>(null);
  const { t } = useTranslation();
  const [{ page, pageCount }, setPageDetails] = useState<paginationDetailsType>(
    { page: 0, pageCount: null },
  );
  const view = useIntersectionObserver({
    ref: div,
    options: { threshold: 1, triggerOnce: false },
  });
  const cache = useQueryClient();
  const [_id, setId] = useState<number>(0);
  const { isLoading: deleteLoading, mutate: _onDelete } = useMutation(
    async (id: number) => {
      try {
        await gate.deleteUser(id);
      } catch (error) {
        console.log('err:', error);
      }
    },
    {
      onSuccess: () => cache.invalidateQueries('users'),
    },
  );
  const {
    fetchNextPage,
    data,
    isLoading,
    isFetchingNextPage,
  } = useInfiniteQuery(
    'users',
    (page) =>
      gate.users({
        page: (page.pageParam as string) ?? ('1' as string),
        limit: '15',
      }),
    {
      getNextPageParam: (lastPage: any, allPage) => {
        setPageDetails({ page: lastPage.page, pageCount: lastPage.pageCount });
      },
    },
  );

  useEffect(() => {
    if (view) {
      let currentPage = page;
      if (pageCount) {
        if (currentPage >= pageCount) return;
      }
      currentPage++;
      fetchNextPage({ pageParam: currentPage });
    }
  }, [view]);

  const onDelete = (id: number) => {
    if (!window.confirm('are you sure ')) return;
    setId(id);
    _onDelete(id);
  };

  return (
    <div className="flex justify-center flex-col  px-5 overflow-hidden w-full min-h-max-content ">
      <div className="flex justify-end items-center my-2">
        <Link to="/user/register/new">
          <Button className="font-black  border w-16 h-10 flex justify-center items-center focus:border-blue-600 text-black rounded-lg text-3xl">
            +
          </Button>
        </Link>
      </div>
      <Table>
        <TableHead
          data={[
            t('name') as string,
            t('function') as string,
            t('start Time') as string,
            t('role') as string,
            t('') as string,
          ]}
        />
        <TableBody>
          {data?.pages.map((page) => {
            return page.data.map(
              ({
                avatar,
                email,
                firstName,
                function: description,
                id,
                lastName,
                role,
                startTime,
              }: any) => {
                return (
                  <tr key={id}>
                    <TableCell>
                      <div className="flex items-center">
                        <div className="flex-shrink-0 h-10 w-10">
                          <img
                            className="h-10 w-10 object-contain rounded-full"
                            src={
                              avatar
                                ? getDownloadAddress(avatar)
                                : AvataPlaceHolder
                            }
                            alt=""
                          />
                        </div>
                        <div className="ml-4">
                          <div className="text-sm font-medium text-gray-900">
                            {firstName + ' ' + lastName}
                          </div>
                          <div className="text-sm text-gray-500">{email}</div>
                        </div>
                      </div>
                    </TableCell>
                    <TableCell>
                      <div className="text-sm text-gray-900">{description}</div>
                    </TableCell>
                    <TableCell>
                      <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
                        {(startTime as string)?.substr(0, 10)}
                      </span>
                    </TableCell>
                    <TableCell>{role}</TableCell>
                    <TableCell>
                      <Link
                        to={'/user/update/' + id}
                        className="text-indigo-600 hover:text-indigo-900"
                      >
                        Edit
                      </Link>
                      <Button
                        onClick={() => onDelete(id)}
                        isLoading={deleteLoading && _id === id}
                        spinnerColor="red"
                        className="text-red-500 mx-4 hover:text-indigo-900"
                      >
                        Delete
                      </Button>
                    </TableCell>
                  </tr>
                );
              },
            );
          })}
        </TableBody>
      </Table>
      <div ref={div} className="w-full h-4 bg-transparent" />
      {isFetchingNextPage || isLoading ? (
        <div className="w-full flex justify-center items-center">
          <Spinner color="gray" className="w-16 h-16" />
        </div>
      ) : null}
    </div>
  );
};

export default Users;
