import {
  RequestNewCompany,
  RequestNewDefaultRegisterQuestion,
  RequestRegisterCompany,
  RequestUpdateCompany,
} from 'types/api/company';
import api from './api';
export default {
  companies: () => api.get('/admin/company'),
  newCompany: (data: RequestNewCompany) => api.post('/admin/company/new', data),
  company: (id: number) => api.get(`/admin/company/${id}`),
  deleteCompany: (id: number) => api.delete(`/admin/company/${id}`),
  getRegisterQuestion: (id: number) =>
    api.get('/admin/register-question/' + id),
  updateRegisterQuestion: (id: number, data: any) =>
    api.put(`/admin/register-question/${id}`, data),
  deleteRegisterQuestion: (id: number) =>
    api.delete(`/admin/register-question/${id}`),
  CreateregisterQuestion: (data: any) =>
    api.post('/admin/register-question/', data),
  createDepartment: (data: any) => api.post('/admin/department', data),
  getDepartment: (id: number) => api.get(`/admin/department/${id}`),
  updateDepartment: (id: number) => api.put(`/admin/department/${id}`),
  deleteDepartment: (id: number) => api.delete(`/admin/department/${id}`),
  registerCompany: (data: RequestRegisterCompany) =>
    api.post('/admin/company/register', data),
  updateCompany: (data: RequestUpdateCompany, id: number) =>
    api.put(`/admin/company/${id}`, data),
  defaultRegisterQuestion: (language: string) =>
    api.get<RequestNewDefaultRegisterQuestion>(
      '/admin/register-question/default/' + language,
    ),
  newDefaultRegisterQuestion: (
    data: RequestNewDefaultRegisterQuestion,
    language: string,
  ) => api.post(`/admin/register-question/default/${language}`, data),
};
