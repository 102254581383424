import React from 'react';
import { Provider } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import { Router } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import store from 'store/index';
import Routes from 'pages/routes';
import { createBrowserHistory } from 'history';

import 'styles/index.css';
import 'cropperjs/dist/cropper.css';
import 'react-toastify/dist/ReactToastify.css';
import 'react-modern-calendar-datepicker/lib/DatePicker.css';

import { SidebarContextProvider } from 'context/navbarContext';
import './i18n';
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

export const history = createBrowserHistory();

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <Provider store={store}>
        <Router history={history}>
          <SidebarContextProvider>
            <ToastContainer
              bodyClassName="toast-body"
              position="top-center"
              autoClose={3000}
              hideProgressBar
              newestOnTop
              closeOnClick
            />
            <Routes />
          </SidebarContextProvider>
        </Router>
      </Provider>
    </QueryClientProvider>
  );
}

export default App;
