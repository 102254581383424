import auth from './auth';
import api from './api';
import company from './company';
import user from './user';
import language from './language';

import { paginationType } from 'types/api/user';
const uploadFile = {
  uploadFile: (
    data: FormData,
    onUploadProgress: ((progressEvent: any) => void) | undefined,
  ) => api.file('/storage/upload', data, onUploadProgress),
};

const tip = {
  tips: (data?: paginationType) => api.get('/admin/tip', data),
};

const setting = {
  getSetting: (data?: paginationType) => api.get('/admin/setting', data),
  postSetting: (data: any) => api.post('/admin/setting', data),
};

export default {
  ...uploadFile,
  ...tip,
  ...user,
  ...auth,
  ...language,
  ...company,
  ...setting,
};
