import React, {
  createContext,
  ReactChild,
  ReactChildren,
  ReactNode,
  useState,
} from 'react';

export const SidebarContext = createContext({
  showMenu: false,
  toggleShowMenu: () => {},
});
type Prop = {
  children: ReactNode;
};
export const SidebarContextProvider = ({ children }: Prop) => {
  const [show, setShow] = useState<boolean>(false);
  const toggleshowMenu = () => {
    setShow((prev) => !prev);
  };

  return (
    <SidebarContext.Provider
      value={{ showMenu: show, toggleShowMenu: toggleshowMenu }}
    >
      {children}
    </SidebarContext.Provider>
  );
};

export default SidebarContext;
