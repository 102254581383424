import { useDispatch } from 'react-redux';
import React, { useContext, useState } from 'react';
import menuContext from 'context/navbarContext';
import { logout } from 'store/reducers/user';
import { DropDown, Button } from 'components';

import { ReactComponent as Profile } from 'assets/svg/icons/profileUser.svg';
import logo from 'assets/images/headstart192.png';
const Header: React.FC = () => {
  const { toggleShowMenu } = useContext(menuContext);
  const [visible, setVisible] = useState<boolean>(false);
  const dispatch = useDispatch();
  return (
    <header className="justify-between p-3  flex border border-gray-300 lg:px-20 w-full ">
      <div className=" hidden lg:flex ml-2 justify-center items-center">
        <a href="https://headstart.work" target="_blunk">
          <img src={logo} className="w-10  h-10" alt="logo" />
        </a>
      </div>
      <div className="hidden lg:block"></div>
      <button
        onClick={() => toggleShowMenu()}
        className="flex btn lg:hidden font-bold cursor-pointer px-3 text-xl flex-col "
      >
        <div className="-mb-3">=</div>
        <div className="-mt-3">=</div>
      </button>

      <div className=" px-3 flex justify-center items-center">
        <Profile
          onClick={() => setVisible(true)}
          className="w-8 cursor-pointer"
          fill="#78859c"
        />
        <DropDown
          className="right-0 mt-5 shadow"
          visible={visible}
          onClose={() => setVisible(false)}
        >
          <div className="bg-white border-gray-300 divide-y divide-gray-300 border p-1 ">
            <Button
              onClick={() => dispatch(logout(''))}
              className="font-semibold  hover:bg-gray-300 text-gray-700 "
            >
              Logout
            </Button>
          </div>
        </DropDown>
      </div>
    </header>
  );
};

export default Header;
