import React from 'react';
import SpinnerIcon from 'assets/svg/Spinner';
export interface SpinnerProps {
  className?: string;
  width?: number;
  height?: number;
  color?: string;
}

const Spinner: React.FC<SpinnerProps> = ({
  className = 'w-5 h-5',
  height,
  width,
  color = 'white',
}) => {
  return (
    <SpinnerIcon
      width={width}
      height={height}
      color={color}
      className={`animate-spin ${className}`}
    />
  );
};

export default Spinner;
