import React, { ReactNode } from 'react';
interface ModalProps {
  children: ReactNode;
  visible: boolean;
  className?: string;
  onClose: VoidFunction;
}

const Modal: React.FC<ModalProps> = ({
  children,
  onClose,
  visible,
  className,
}) => {
  return (
    <>
      {visible && (
        <div
          onClick={onClose}
          className="bg-black opacity-25 right-0 left-0 top-0 bottom-0 z-20 w-screen h-screen fixed"
        ></div>
      )}
      <div
        className={` w-full absolute left-0  top-0  flex justify-center items-center ${
          visible && 'h-screen'
        }`}
      >
        <div
          className={` ${className} transform transition-all  duration-700 ${
            !visible ? '-translate-y-20 opacity-0' : 'opacity-100 translate-x-0'
          }  fixed z-50 `}
        >
          {children}
        </div>
      </div>
    </>
  );
};

export default Modal;
