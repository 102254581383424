import React, { useEffect, useRef, useState } from 'react';
import { Table, TableBody, TableCell, TableHead, Spinner } from 'components';
import { useInfiniteQuery } from 'react-query';
import { useTranslation } from 'react-i18next';

import gate from 'gate';

import { useIntersectionObserver } from 'hooks/use-intersection-observer';
type paginationDetailsType = {
  page: number;
  pageCount: number | null;
};
export interface DashboardProps {}

const Tips: React.FC<DashboardProps> = () => {
  const div = useRef<HTMLDivElement>(null);
  const { t } = useTranslation();
  const [{ page, pageCount }, setPageDetails] = useState<paginationDetailsType>(
    { page: 0, pageCount: null },
  );
  const view = useIntersectionObserver({
    ref: div,
    options: { threshold: 1, triggerOnce: false },
  });

  const {
    fetchNextPage,
    data,
    isLoading,
    isFetchingNextPage,
  } = useInfiniteQuery(
    'tips',
    (page) =>
      gate.tips({
        page: (page.pageParam as string) ?? ('1' as string),
        limit: '15',
      }),
    {
      getNextPageParam: (lastPage: any, allPage) => {
        setPageDetails({ page: lastPage.page, pageCount: lastPage.pageCount });
      },
    },
  );

  useEffect(() => {
    if (view) {
      let currentPage = page;
      if (pageCount) {
        if (currentPage >= pageCount) return;
      }
      currentPage++;
      fetchNextPage({ pageParam: currentPage });
    }
  }, [view]);

  return (
    <div className="flex justify-center flex-col  px-5 overflow-hidden w-full min-h-max-content ">
      <Table>
        <TableHead data={[t('number') as string, t('content') as string]} />
        <TableBody>
          {data?.pages?.map((page) => {
            return page?.data?.map(({ content, id }: any, index: number) => {
              return (
                <tr key={id}>
                  <TableCell>{index + 1}</TableCell>
                  <TableCell>{content}</TableCell>
                </tr>
              );
            });
          })}
        </TableBody>
      </Table>
      <div ref={div} className="w-full h-4 bg-transparent" />
      {isFetchingNextPage || isLoading ? (
        <div className="w-full flex justify-center items-center">
          <Spinner color="gray" className="w-16 h-16" />
        </div>
      ) : null}
    </div>
  );
};

export default Tips;
