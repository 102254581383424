import React, { ReactNode, useContext, useState } from 'react';
import menuContext from 'context/navbarContext';
import Header from './header';
import NavBar from './navbar';
export interface LayoutProps {
  children: ReactNode;
  className?: string;
  dir?: 'ltr' | 'rtl';
}

const Layout: React.FC<LayoutProps> = ({
  children,
  className,
  dir = 'ltr',
}) => {
  const [show, setShow] = useState<boolean>(false);
  const { showMenu, toggleShowMenu } = useContext(menuContext);
  return (
    <div
      style={{ direction: dir }}
      className="w-full  min-h-screen  relative bg-blue-350"
    >
      <div className="w-full flex flex-col bg-white ">
        <Header />
        <NavBar />
      </div>

      <main className="flex text-gray-700 py-5 px-3 lg:px-20 w-full ">
        {children}
      </main>
    </div>
  );
};

export default Layout;
