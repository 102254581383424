import LoadingPage from 'components/LoadingPage';
import gate from 'gate';
import React from 'react';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';

const Company: React.FC = () => {
  const { id } = useParams<any>();
  const { data, error, isLoading } = useQuery(`company/${id}`, () =>
    gate.company(id),
  );
  if (isLoading) {
    return <LoadingPage />;
  }

  return (
    <div className="w-full overflow-hidden px-5  flex h-screen text-xl font-bold justify-center flex-col items-start">
      <div className=" grid lg:grid-cols-3 w-full ">
        <div className="py-3 flex flex-col justify-center items-center ">
          <img
            src="https://picsum.photos/id/237/200/300"
            className="shadow border-gray-300 border w-48 h-48 object-cover"
          />
          <div className="py-5   text-center text-xl">NAme vomani</div>
        </div>
        <div className="h-64 lg:col-span-2 grid lg:grid-cols-2 bg-yellow-600">
          <div className="bg-gray-400"></div>
          <div className="bg-gray-100"></div>
        </div>
      </div>

      <pre className="text-xs">{JSON.stringify(data as any, null, 2)}</pre>
    </div>
  );
};

export default Company;
