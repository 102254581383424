import React, {ReactChildren} from 'react';
export interface DropdownProps {
  heightClassName?: string;
  chidren?: ReactChildren;
  visible: boolean;
  className?: string;
  onClose: VoidFunction;
}

const Dropdown: React.FC<DropdownProps> = ({
  children,
  visible,
  onClose,
  className,
  heightClassName = 'h-max-content',
}) => {
  return (
    <>
      {visible && (
        <div
          onClick={() => onClose()}
          className="z-0  fixed w-screen h-screen right-0 top-0 left-0 bottom-0 bg-transparent"
        />
      )}
      <div className="relative">
        <div
          className={`absolute ${className} transform transition-all duration-300 z-20 overflow-hidden ${
            visible ? `opacity-100 ${heightClassName}` : 'h-0  opacity-0'
          } `}>
          {children}
        </div>
      </div>
    </>
  );
};

export default Dropdown;
