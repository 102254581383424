import React from 'react';
import { Spinner } from 'components';

const LoadingPage: React.FC = () => {
  return (
    <div className="w-full flex animate-fadeRight justify-center h-screen items-center">
      <Spinner color="#abbfd3" className="w-48 h-64" />
    </div>
  );
};

export default LoadingPage;
