import React, { useState } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  Spinner,
  LoadingPage,
  Button,
  Modal,
  Input,
} from 'components';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import gate from 'gate';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { Controller, FieldError, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { LanguageType } from 'types/api/language';
import { format } from 'prettier';
import { toast } from 'react-toastify';
export interface DashboardProps {}

const Languages: React.FC<DashboardProps> = () => {
  const [_id, setId] = useState<number | string>(0);

  const { t } = useTranslation();
  const cache = useQueryClient();
  const schema = yup.object().shape({
    name: yup.string().required(t('field is required')),
  });
  const [open, setOpen] = useState<boolean>(false);

  const { mutate: _onDelete, isLoading: deleteLoading } = useMutation(
    async (id: number | string) => {
      try {
        await gate.deleteLanguage(id);
        cache.invalidateQueries('languages');

        setId(0);
      } catch ({ data }) {
        toast.error(data.detail.message);
        (data.detail.response.message as string[]).forEach((msg) =>
          toast.error(msg),
        );
      }
    },
  );
  const { mutate, isLoading: newLanguageLoading } = useMutation(
    async (data: LanguageType) => {
      try {
        await gate.newLanguage(data);
        cache.invalidateQueries('languages');
        toast.success(t('created successfully'));
        setOpen(false);
      } catch ({ data }) {
        toast.error(data.detail.message);
        (data.detail.response.message as string[]).forEach((msg) =>
          toast.error(msg),
        );
      }
    },
  );

  const defaultValues: LanguageType = {
    name: '',
    title: '',
  };

  const { control, errors, handleSubmit } = useForm<LanguageType>({
    defaultValues,
    resolver: yupResolver(schema),
  });

  const onSubmit = (data: LanguageType) => {
    mutate(data);
  };

  const onDelete = (id: number | string) => {
    setId(id);
    _onDelete(id);
  };

  const { data, isLoading } = useQuery(
    'languages',
    async () => await gate.getLanguages(),
  );
  if (isLoading) {
    return <LoadingPage />;
  }
  return (
    <>
      <div className="flex p-4 justify-end items-center my-2">
        <Button
          onClick={() => setOpen(true)}
          className="font-black  border w-16 h-10 flex justify-center items-center focus:border-blue-600 text-black rounded-lg text-3xl"
        >
          +
        </Button>
      </div>
      <div className="flex justify-center flex-col  px-5 overflow-hidden w-full min-h-max-content ">
        <Table>
          <TableHead
            data={[
              t('number') as string,
              t('name') as string,
              t('title') as string,
            ]}
          />
          <TableBody>
            {data?.map(({ id, name, title }, index: number) => {
              return (
                <tr key={id}>
                  <TableCell>{index + 1}</TableCell>
                  <TableCell>{name}</TableCell>

                  <TableCell>{title}</TableCell>
                  <TableCell>
                    <Button
                      spinnerColor="red"
                      onClick={() => onDelete(id)}
                      className="text-xs text-red-600"
                      isLoading={deleteLoading && id === _id}
                    >
                      delete
                    </Button>
                  </TableCell>
                </tr>
              );
            })}
          </TableBody>
        </Table>
      </div>

      <Modal
        className="w-1/2 rounded shadow-md overflow-hidden "
        onClose={() => setOpen(false)}
        visible={open}
      >
        <form
          onSubmit={handleSubmit(onSubmit)}
          className=" w-full p-4 flex flex-col  bg-white"
        >
          <Controller
            as={Input}
            control={control}
            name="name"
            inputClassName="p-2 rounded"
            className="my-2 w-full"
            error={((errors?.name as unknown) as FieldError)?.message as string}
            title={t('Name')}
          />
          <Controller
            as={Input}
            name="title"
            inputClassName="p-2 rounded"
            control={control}
            className="my-2 w-full"
            error={
              ((errors?.title as unknown) as FieldError)?.message as string
            }
            title={t('Title')}
          />

          <div className="w-full flex justify-center p-3">
            <Button
              className="bg-blue-600 text-white"
              isLoading={newLanguageLoading}
              type="submit"
            >
              {t('save')}
            </Button>
          </div>
        </form>
      </Modal>
    </>
  );
};

export default Languages;
